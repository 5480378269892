import React from "react";
import styles from "./LoadingSpinnerOverlay.module.scss";

export const LoadingSpinnerOverlay = React.memo((): JSX.Element => {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinner}></div>
    </div>
  );
});
