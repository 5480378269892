import { useCallback, useMemo, useState } from "react";
import { CSVLink } from "react-csv";

import { Location } from "../../../../redux/models/location";
import { Player } from "../../../../redux/models/player";
import {
  getScoreByGame,
  getTimesPassedLevel,
  isPersonalRecord,
} from "../../utils";

import { Stars } from "./components/Stars";
import { TextInfo } from "./components/TextInfo";
import { Rank } from "./components/Rank";

import styles from "./Summary.module.scss";
import { exportPlayer } from "../../../../utils";
import { useActions, useAnalyticsEventTracker } from "../../../../hooks";
import {
  getMonthlyScore,
  resetMonthlyScore,
} from "../../../../utils/monthlyScore";
import { NewRank } from "./components/NewRank";

type SummaryProps = {
  player: Player;
  location: Location;
};

export const Summary = ({ player, location }: SummaryProps): JSX.Element => {
  const { eventTracker } = useAnalyticsEventTracker("Account Summary");
  const { fakeAPICall } = useActions();
  const [forceRefresh, setForceRefresh] = useState(false);

  let topScores = 0;
  let timesPassedTotal = 0;
  location.rooms.forEach(({ games }) =>
    games.forEach(({ levels }) =>
      levels.forEach(({ game_id, level_id, top_score }) => {
        const score = getScoreByGame(player, game_id, level_id);
        topScores += isPersonalRecord(score, top_score) ? 1 : 0;
        timesPassedTotal += getTimesPassedLevel(player, game_id, level_id);
      })
    )
  );

  const newRank = useMemo(
    () => Math.floor(player.local_score / 25000),
    [player.local_score]
  );

  // const nextRank = getNextRank({
  //   cityLevels: location.levels_count,
  //   levels: player.levels,
  //   rank: player.player_rank,
  //   stars: player.stars,
  // });

  // const nextRankNumber = player.player_rank + 1;
  // const showNextRankInfo = nextRankNumber < MAX_RANK + 1;

  const onExportDataClick = useCallback(() => {
    fakeAPICall(`/players/exportData/${player.player_name}`);
    eventTracker(`Export Data (${player.player_name})`);
  }, [fakeAPICall, eventTracker, player.player_name]);

  const onResetMonthlyScoreClick = useCallback((): void => {
    resetMonthlyScore({ player, location });
    setForceRefresh(!forceRefresh);
  }, [player, location, setForceRefresh, forceRefresh]);

  return (
    <div className={styles.wrapper}>
      <CSVLink
        className={styles.exportData}
        data={exportPlayer(player, location)}
        filename={`${player.player_name} Activate Scores`}
        onClick={onExportDataClick}
      >
        <strong>Export Data</strong>
      </CSVLink>
      <button
        className={styles.resetMonthlyScore}
        onClick={onResetMonthlyScoreClick}
      >
        Reset Monthly Score
      </button>
      <div className={styles.starsWrapper}>
        <Stars stars={player.stars} />
      </div>
      <TextInfo label="New Rank" value={<NewRank rank={newRank} />} />
      <TextInfo label="Old Rank" value={<Rank rank={player.player_rank} />} />

      {/* <TextInfo
        label="Old Ranking System Stars"
        value={`${player.starsOldRankingSystem} (+ bonus chests)`}
      /> */}
      <TextInfo label={`${location.name} Score`} value={player.local_score} />
      <TextInfo
        label={"Monthly Score"}
        value={`~ ${getMonthlyScore({ player, location })}`}
      />
      <TextInfo label="Leaderboard Rank" value={player.local_rank} />
      <TextInfo
        label="Levels"
        value={`${player.passedLevels}/${location.levels_count}`}
      />
      <TextInfo label="Top Scores" value={topScores} />
      <TextInfo label="Total Wins" value={timesPassedTotal} />

      {/* {showNextRankInfo && (
        <>
          <TextInfo label={`Rank ${nextRankNumber} in`} value={nextRank} />
          <TextInfo
            label="Next Reward"
            value={getNextRankReward(nextRankNumber)}
          />
        </>
      )} */}
      {/* <TextInfo label="Coins" value={`${timesPassedTotal + newRank * 10}`} /> */}
    </div>
  );
};
