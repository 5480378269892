export const MAX_RANK = 8;

export const INITIAL_BINDED_PLAYERS: { [key: string]: string } = {
  "1": "Gondim",
  "2": "Liliii",
  "3": "OuiOuiBaguette",
  "4": "Sweaty Spaghetti",
  "5": "Swaggy-Tacos",
  "6": "",
  "7": "",
  "8": "",
  "9": "",
};

export const BINDED_PLAYERS_LOCAL_STORAGE_KEY = "binded-players";

// if activate enables this game again, this must be removed or changed
export const INACTIVE_GAME_ID = 21;
