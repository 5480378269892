import { useCallback, useMemo, useState } from "react";

import styles from "./Room.module.scss";

import { ListPlayersToRemove } from "./components/ListPlayersToRemove";
import { ShowGameScores } from "./components/ShowGameScores";
import { Filters } from "../Filters/Filters";
import { SearchInput, Title, RadioItemValue } from "../../../../components";
import {
  useActions,
  useAnalyticsEventTracker,
  useOtherLocations,
  usePlayerInfo,
} from "../../../../hooks";
import { Room as RoomModel } from "../../../../redux/models";
import {
  DropdownSelect,
  Option,
} from "../../../../components/DropdownSelect/DropdownSelect";
import { getOptionByLabel } from "../../../../components/DropdownSelect/utils";
import { Banner } from "../../../../components/Banner";

const locationOptions: Option[] = [
  { label: "American Dream", value: "american-dream" },
  { label: "Atlanta", value: "atlanta" },
  { label: "Birmingham", value: "birmingham" },
  { label: "Brampton", value: "brampton" },
  { label: "Burlington", value: "burlington" },
  { label: "Calgary North", value: "calgary-north" },
  { label: "Calgary Southwest", value: "calgary-southwest" },
  { label: "Chicago", value: "chicago" },
  { label: "Charlotte", value: "charlotte" },
  { label: "Cincinnati", value: "cincinnati" },
  { label: "Dallas Southlake", value: "dallas-southlake" },
  { label: "Edmonton South", value: "edmonton-south" },
  { label: "Edmonton West", value: "edmonton-west" },
  { label: "Gatlinburg", value: "gatlinburg" },
  { label: "Halifax", value: "halifax" },
  { label: "Houston", value: "houston" },
  { label: "Lexington", value: "lexington" },
  { label: "Louisville", value: "louisville" },
  { label: "Mississauga", value: "mississauga" },
  { label: "Plano", value: "plano" },
  { label: "Scarborough", value: "scarborough" },
  { label: "Winnipeg East", value: "winnipeg-east" },
  { label: "Winnipeg West", value: "winnipeg-west" },
];

type RoomProps = {
  room?: RoomModel;
  location: string;
};

export const Room = ({ room, location }: RoomProps): JSX.Element => {
  const [improveScoreBy, setImproveScoreBy] = useState<RadioItemValue>(200);
  const [selectedOption, setSelectedOption] = useState<Option>(
    getOptionByLabel(location, locationOptions)
  );
  const { comparePlayers } = usePlayerInfo();

  const { eventTracker } = useAnalyticsEventTracker("Room");

  const { fetchListPlayers } = useActions();
  const { isComparingWithOtherLocations } = useOtherLocations();

  const handleOnSearchClick = useCallback(
    (playerNames: string) => {
      eventTracker("compare players");
      fetchListPlayers(playerNames, selectedOption.label);
    },
    [selectedOption.label, eventTracker, fetchListPlayers]
  );

  const handleImproveScoreBy = useCallback(
    (value: RadioItemValue) => {
      eventTracker(`improve score by ${value}`);
      setImproveScoreBy(value);
    },
    [eventTracker]
  );

  const handleOnSetSelectedOption = useCallback((option: Option) => {
    setSelectedOption(option);
  }, []);

  const isComparing = useMemo(
    () => isComparingWithOtherLocations || comparePlayers.length > 0,
    [isComparingWithOtherLocations, comparePlayers.length]
  );

  if (!room?.name) {
    return <>Game not found</>;
  }

  return (
    <>
      <Banner
        type="alert"
        message="Disclaimer - Game Scores are no longer reliable"
      />

      <div className={styles.searchWrapper}>
        <div>
          <Filters
            improveScoreBy={improveScoreBy}
            handleImproveScoreBy={handleImproveScoreBy}
          />
          {!isComparing && (
            <p>
              <span className={styles.magentaSquare}></span> Monthly Score
            </p>
          )}
        </div>
        {!isComparingWithOtherLocations && (
          <div className={styles.searchInputWrapper}>
            <SearchInput
              buttonLabel="Compare"
              onSearchClick={handleOnSearchClick}
              size="small"
              placeholder="Profile name to compare"
            />
            <DropdownSelect
              options={locationOptions}
              selectedOption={selectedOption}
              setSelectedOption={handleOnSetSelectedOption}
            />
            <div className={styles.listPlayers}>
              <ListPlayersToRemove />
            </div>
          </div>
        )}
      </div>
      <Title additionalStyles={styles.title}>{room.name}</Title>
      <ShowGameScores games={room.games} improveScoreBy={improveScoreBy} />
    </>
  );
};
