import { Link as ReactLink, LinkProps } from "react-router-dom";
import styles from "./Link.module.scss";
import classNames from "classnames";
import React from "react";

export const Link = React.memo(
  ({ className, ...props }: LinkProps): JSX.Element => {
    return (
      <ReactLink className={classNames(styles.link, className)} {...props} />
    );
  }
);
