import classNames from "classnames";
import styles from "./NewTag.module.scss";
import React, { useMemo } from "react";

type NewTagProps = {
  className?: string;
};

export const NewTag = React.memo(({ className }: NewTagProps): JSX.Element => {
  const wrapperClassName = useMemo(
    () => classNames(styles.wrapper, className),
    [className]
  );
  return (
    <span className={wrapperClassName}>
      <strong>NEW</strong>
    </span>
  );
});
