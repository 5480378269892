import { useCallback, useEffect, useState } from "react";

import styles from "./Leaderboard.module.scss";

import { useActions, useTypedSelector } from "../../../../hooks/";
import classNames from "classnames";
import { PositionDifference } from "./components/PositionDifference";
import { LeaderboardScoreDifference } from "./components/LeaderboardScoreDifference";
import { Checkbox } from "../../../../components";
import { NewRank } from "../Summary/components/NewRank";

export const WorldLeaderboard = (): JSX.Element => {
  const { fetchLeaderboard } = useActions();

  const [showHighlightLocationPlayers, setShowHighlightLocationPlayers] =
    useState(false);

  const {
    player: { player_name },
    location: { name: locationName },
    leaderboard,
    isLoadingLeaderboard,
    errorMessageLeaderboard,
  } = useTypedSelector(({ playerInfo }) => playerInfo);

  useEffect(() => {
    fetchLeaderboard("world");
  }, [fetchLeaderboard]);

  const onShowHighlightLocationPlayersClick = useCallback(() => {
    setShowHighlightLocationPlayers(!showHighlightLocationPlayers);
  }, [showHighlightLocationPlayers]);

  return (
    <div>
      <div className={styles.optionsWrapper}>
        <Checkbox
          value={showHighlightLocationPlayers}
          onValueChange={onShowHighlightLocationPlayersClick}
          label="Highlight players from your location"
          data-trackid="show highlighted players from your location"
        />
      </div>
      <h1>World Leaderboard</h1>
      <div className={styles.tableWrapper}>
        {isLoadingLeaderboard ? (
          <p className={styles.loading}>Calculating Ranks</p>
        ) : errorMessageLeaderboard ? (
          <p className={styles.error}>{errorMessageLeaderboard}</p>
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th className={styles.leftAlign}>Name</th>
                <th className={styles.leftAlign}>Score</th>
                <th className={styles.leftAlign}>Location</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map(
                (
                  {
                    players,
                    local_score,
                    city,
                    positionDifference,
                    scoreDifference,
                  },
                  i
                ) => {
                  const isSameLocation = locationName === city;
                  const isCurrentUser = (players as string) === player_name;
                  const rowStyle = classNames(styles.row, {
                    [styles.currentUser]: isCurrentUser,
                    [styles.highlight]:
                      showHighlightLocationPlayers &&
                      isSameLocation &&
                      !isCurrentUser,
                  });
                  const position = i + 1;
                  const rank = Math.floor(local_score / 25000);

                  return (
                    <tr key={i} className={rowStyle}>
                      <td className={styles.centerAlign}>
                        <NewRank rank={rank} size="small" />
                      </td>
                      <td className={styles.centerAlign}>{position}</td>
                      <td className={styles.differenceWrapper}>
                        {players as string}
                        <PositionDifference
                          positionDifference={positionDifference}
                        />
                      </td>
                      <td className={styles.leftAlign}>
                        {local_score}
                        <LeaderboardScoreDifference
                          scoreDifference={scoreDifference}
                        />
                      </td>
                      <td className={styles.leftAlign}>{city}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
