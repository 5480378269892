import { useMemo } from "react";
import styles from "./LoadingDiv.module.scss";

const LOADING_PLACEHOLDERS: string[] = [
  "SUPERING GRIDS",
  "SHOOTING HOOPS",
  "CLIMBING WALLS",
  "HIDING WORDS",
  "CHARGING LASERS",
];

export const LoadingDiv = (): JSX.Element => {
  const randomPlaceholder = useMemo(
    () =>
      LOADING_PLACEHOLDERS[
        Math.floor(Math.random() * LOADING_PLACEHOLDERS.length)
      ],
    []
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.spinner}></div>
      <div className={styles.placeholder}>{randomPlaceholder}</div>
    </div>
  );
};
