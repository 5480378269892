import { useCallback } from "react";
import { VscStarFull } from "react-icons/vsc";

import styles from "./Activate.module.scss";

import { BackButton } from "./components/BackButton/BackButton";
import { Badges } from "./components/Badges/Badges";
import { Summary } from "./components/Summary/Summary";
import { Room } from "./components/Room/Room";
import { Leaderboard } from "./components/Leaderboard/Leaderboard";
import { WorldLeaderboard } from "./components/Leaderboard/WorldLeaderboard";
import { SearchPlayer } from "./components/SearchPlayer/SearchPlayer";
import { BindedPlayers } from "./components/BindedPlayers/BindedPlayers";

import { SideMenu } from "./components/SideMenu/SideMenu";
import { useTypedSelector } from "../../hooks";
import { LoadingDiv } from "../../components/LoadingDiv/LoadingDiv";
import { ChooseLocationModal } from "./components/ChooseLocationModal/ChooseLocationModal";
import { LoadingSpinnerOverlay } from "../../components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import { Banner } from "../../components/Banner";
import { useActivateHook } from "./hook";

export const Activate = (): JSX.Element | null => {
  const {
    playerInfo: { player, location, isLoading, isLoadingCompareAction },
    lastUpdated: { lastUpdatedLeaderboards, lastUpdatedPlayers, isApiDown },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));

  const {
    menuActive,
    onRoomClick,
    room,
    handleBackButtonClick,
    isModalOpen,
    setIsModalOpen,
    setMenuActive,
  } = useActivateHook();

  const renderContent = useCallback((): JSX.Element => {
    switch (menuActive) {
      case "Account Summary":
        return <Summary player={player} location={location} />;
      case "Games":
        const _room = location.rooms.find(
          (r) =>
            r.id === room.roomId || (!room.roomId && r.name === room.roomName)
        );
        return <Room room={_room} location={location.name} />;
      case "Leaderboards":
        return <Leaderboard />;
      case "World Leaderboard":
        return <WorldLeaderboard />;
      case "Badges":
        return <Badges playerName={player.player_name} />;
      default:
        return <></>;
    }
  }, [menuActive, player, location, room]);

  const renderLastUpdated = useCallback((): JSX.Element => {
    if (isApiDown) {
      return (
        <Banner type="alert" message="API is down - ping Gondim on Discord" />
      );
    } else {
      return (
        <div className={styles.lastUpdated}>
          <p>Players last updated: {lastUpdatedPlayers}</p>
          <p>Leaderboards last updated: {lastUpdatedLeaderboards}</p>
        </div>
      );
    }
  }, [isApiDown, lastUpdatedLeaderboards, lastUpdatedPlayers]);

  return (
    <div className={styles.backgroundImage}>
      <ChooseLocationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // TODO: needs to be worked on in the future
        // showDontAskAgainCheckbox
        menuActive={menuActive}
      />
      {renderLastUpdated()}
      {/* {!isApiDown && (
        <p className={styles.playStrategyButton}>
          <Link to="/strategy/1">Play Strategy</Link>
        </p>
      )} */}
      <div className={styles.wrapper}>
        {player.player_name ? (
          <div>
            {isLoadingCompareAction && <LoadingSpinnerOverlay />}
            <BackButton onClick={handleBackButtonClick} />
            <div className={styles.wrapperPlayerBasicInfo}>
              <div>{player.player_name}</div>
              <div className={styles.starWrapper}>
                <VscStarFull className={styles.star} /> {player.stars} Stars
              </div>
            </div>
            <hr />
            <div className={styles.content}>
              <SideMenu
                menuActive={menuActive}
                onRoomClick={onRoomClick}
                room={room}
                setMenuActive={setMenuActive}
              />
              <div className={styles.information}>{renderContent()}</div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <LoadingDiv />
            ) : (
              <>
                <SearchPlayer />
                <BindedPlayers />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
