import rank10 from "../../../../../images/ranks/Rank_10-19.png";
import rank20 from "../../../../../images/ranks/Rank_20-29.png";
import rank30 from "../../../../../images/ranks/Rank_30-39.png";
import rank40 from "../../../../../images/ranks/Rank_40-49.png";
import rank50 from "../../../../../images/ranks/Rank_50-59.png";
import rank60 from "../../../../../images/ranks/Rank_60-69.png";
import rank70 from "../../../../../images/ranks/Rank_70-79.png";
import rank80 from "../../../../../images/ranks/Rank_80-89.png";
import rank90 from "../../../../../images/ranks/Rank_90-99.png";
import rank100 from "../../../../../images/ranks/Rank_100.png";
import styles from "./NewRank.module.scss";
import { useCallback, useMemo } from "react";

type NewRankProps = {
  rank: number;
  size?: "large" | "small";
};

export const NewRank = ({
  rank,
  size = "large",
}: NewRankProps): JSX.Element => {
  const rankImageMap: Record<string, string> = useMemo(
    () => ({
      "10-19": rank10,
      "20-29": rank20,
      "30-39": rank30,
      "40-49": rank40,
      "50-59": rank50,
      "60-69": rank60,
      "70-79": rank70,
      "80-89": rank80,
      "90-99": rank90,
    }),
    []
  );

  const getRankImage = useCallback(
    (rank: number) => {
      for (const range in rankImageMap) {
        const [min, max] = range.split("-").map(Number);
        if (rank >= min && rank <= max) {
          return rankImageMap[range];
        }
      }

      if (rank > 99) {
        return rank100;
      }

      return ""; // Fallback if rank is out of bounds
    },
    [rankImageMap]
  );

  const rankImage = getRankImage(rank);
  const imageSizeClass =
    size === "large" ? styles.largeImage : styles.smallImage;
  const textSizeClass = size === "large" ? styles.largeText : styles.smallText;

  return (
    <div className={styles.wrapper}>
      {rankImage ? (
        <>
          <img src={rankImage} alt="Rank" className={imageSizeClass} />
          <div className={textSizeClass}>{rank}</div>
        </>
      ) : (
        <div>{rank}</div>
      )}
    </div>
  );
};
